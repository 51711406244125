@import 'mixins';

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.spin {
  animation: spin 3s linear infinite;
}

.scale-hover {
  @include scale-hover;
}